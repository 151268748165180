.App {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.side-bar {
  display: flex;
    flex-direction: column;
    width: 20vw;
    height: 100vh;
    overflow: auto;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1), 0 2px 5px rgba(0, 0, 0, 0.2);
}


.side-bar-header {
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
  border-bottom: 3px solid #0068a6;
}

.side-bar-header h1 {
  margin: 0;
  font-size: 36pt;
}

.api-link {
  margin-top: 10px;
  font-size: 12pt;
  transition: all 0.1s ease;
  padding-left: 30px;
}

.api-link:hover {
  cursor: pointer;
}

#api-data {
  width: 90vw;
  padding-left: 30px;
  padding-top: 30px;
  overflow-y: scroll;
}

p {
  margin: 0px;
}
li.linkClass{
  margin-left: 30px;
  list-style: none;
  margin-top: 3px;
}
li:hover {
  cursor: pointer;
}
.active {
  color: #005f98d6;
  text-shadow: 0px 0px 0.5px #1682c1;
}

.comtech-header {
  border-bottom: 5px solid #0068a6;
}

.navbar {
  padding: 0.5rem 0 !important;
}

.header {
  flex-shrink: 0;
  min-height: 96vh;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.comtech-logo-margin {
  margin: 15px 33px;
}
.map {
  position: relative;
  height: 250px;
  border-bottom: 1px solid #0068a6;
}
.absolute-position {
  position: absolute;
}
.relative-position {
  position: relative;
}
.pin {
  margin-top: 84px;
  margin-left: 80%;
  position: absolute;
  z-index: 14;
}

.doc-txt {
  position: absolute;
  margin-top: 104px;
  margin-left: 144px;
  height: 48px;
  z-index: 12;
  font-size: 30px;
  font-weight: 600;
}

.float-container {
  position: relative;
  padding: 20px;
  margin: auto;
  margin-top: 0px;
}

.float-child-left {
  float: left;
}
.float-child-right {
  float: right;
}

.img {
  height: 250px;
  z-index: 5;
  width:auto;
  position: absolute;
  opacity: 1000;
  background-color: #ffffff;
}

.img-margin {
  margin-right: 163px;
}

.text {
  z-index: 100;
  position: absolute;
  color: #160c0c;
  font-size: 24px;
  font-weight: bold;
}

.subText {
  z-index: 100;
  position: absolute;
  font-weight: 500;
  color: #6f6b6b;
  font-size: 16px;
}

.customText {
  z-index: 100;
  color: #160c0c;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  position: relative;
  top: 9px;
}
.innerDiv {
  margin-top: 24px;
}
.center {
  text-align: center;
}

.customSubText {
  z-index: 100;
  font-weight: 500;
  color: #6f6b6b !important;
  font-size: 16px;
  background-color: #f8f8f8;
  margin: 0px 27px;
}

.restApi {
  top: 51px;
  right: 432px;
}
.mapApi {
  top: 94px;
  right: 465px;
}

.geoApi {
  top: 134px;
  right: 443px;
}

.psapApi {
  top: 173px;
  right: 462px;
}

.background-img {
  background-image: url("./img/docicon.svg");
  width: 184px;
  height: 258px;
  background-repeat: no-repeat;
}

.background-banner {
  background-image: url("./img/map.svg");
  background-size: cover;
}
.sdkApi {
  top: 51px;
  right: 258px;
}
.jsApi {
  top: 94px;
  right: 240px;
}
.andApi {
  top: 135px;
  right: 248px;
}
.iosApi {
  top: 173px;
  right: 260px;
}

.netApi {
  top: 211px;
  right: 258px;
}

.paddingIcon {
  padding: 12px;
}


.footer .link a {
  color: #615b5b;
  border-right: 1px solid #000;
  padding: 0 8px;
}

.footer .last-link a {
  color: #615b5b;
  padding-left: 8px;
  padding-right: 12px;
}
.footer {
  width: 100%;
  margin-bottom: 11px;
  bottom: 0;
  height: 20px;
  background: rgb(247 249 247 / 0%);
  font-size: 0.875rem ;
  color: #615b5b;
  font-weight: normal;
}
.comtech-rights {
  float: left;
  padding-left: 12px;
}

.customBorder {
  border: 16px solid #f8f8f8;
  border-radius: 31px;
  box-shadow: 0 0 0 3px #f8f8f8;
  width: 155px;
  height: 230px;
  margin: 19px;
}

a {
  text-decoration: none !important;
  color:#0068a6 !important;
}
a:visited {
  text-decoration: none !important;
  color: #0068a6 !important;
}
.tabClass{
  list-style: none;
  display: inline-block;
  margin: 20px 0px 0px 30px;
  font-size: 17px;
  color: rgb(112,112,112);
  padding-bottom: 3px;
  cursor: pointer;
}
.selectedTab{
  border-bottom: 4px solid rgb(112,112,112);
  font-weight: bolder;
}
.tabDiv{
  border-bottom: 1px solid black;
}
.sdkClass{
  position: relative;
  left: 90px;
}
p span {
  margin-left: 5px;
}
p img{
  margin-bottom: 2px;
}
.docClass{
  position: relative;
  right: 20px;
  padding-left: 15px;
  border-left: 2px solid rgb(112,112,112);
  height: 20px;
  float: right;
  margin-top: 7px;
  color: rgb(112,112,112);
  font-weight: bolder;
}
.boldClass {
  font-weight: bolder;
}

.iframeClass {
  position: fixed;
    top: 0px;
    bottom: 0px;
    right: 0px;
    width: 100%;
    border: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}
.mainDiv{
position: relative;
}

.iframeDiv {
  position: fixed;
  top: 55px;
  height: 94vh;
  left: 0;

}
.navi {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
}
.bgDiv{
  background:white;
}

.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  margin-top: 12px;
}

.grid-container2 {
  margin-top: 10px;
  display: flex;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-left: 10%;
  margin-right: 10%;
}
.grid-child {
  background-color: #f9fbfd;
  width: 400px;
  height: 227px;
  border: 3px solid #0068a629;
}

.comp {
  text-align: center;

}

.subDiv{
  top: 109px;
  position: absolute;

  width: 100%;
}
.upperDiv{
  position: relative;
}

.linkSubComp{
  color: #0068a6;
    padding: 10px;
}

.overview{
  position: relative;
    top: 57px;
}

.description {
  max-height: 68px;
    min-height: 68px;
    height: 68px;
    overflow-wrap: break-word;
    padding: 0px 3px;
}

.font14{
  font-size: 14px;
}

.column {
  float: left;
  width: 50%;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.border-top{
  border-top: 1px solid #0068a6;
}

.margin-map-editor {
  margin-top: 12px;
  margin-bottom: 60px;
}

.background-img-mapeditor {
  background-image: url("./img/3dmap.jpg");
  height: inherit;
  background-repeat: no-repeat;
}

.fs-30-b-600{
  font-size: 30px;
  font-weight: 600;
}

.padding-editor-text{
  padding: 44px 0px 0px 68px;
}

.button-css {
  background-color: #0068a6;
  border-radius: 970px;
  width: 111px;
  padding: 8px;
  color: white;
  text-align: center;
  margin-left: 65px;
}